<template>
    <div>
        <div class="item clearfix" v-for="(item,index) in list" :key="index">
            <div class="pic" @click="goPages('/Tasting/user/dynamic/add?id='+item.id)">
                <img :src="item.images" mode="aspectFill" />
            </div>
            <div class="con" @click="goPages('/Tasting/user/dynamic/add?id='+item.id)">
                <div class="tit Ellipses3">{{item.title}}</div>
                <div class="time">{{item.create_time}}</div>
            </div>
            <span class="status">{{item.status_name}}</span>
            <!-- <switch wx:if="{{item.status==1}}" checked="{{item.is_show==1?false:true}}" color="#e93323" data-index="{{index}}" bindchange="switchChange"/> -->
            <div class="switch"><ToggleSwitch ref="ToggleSwitch" :speed='item.is_show' @change="dtShows(item.id)"></ToggleSwitch></div>
        </div>
        <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
        </div>
        <div class="Loading" v-if="finished">没有更多了~</div>
        <EditWindow :url="url"></EditWindow>
    </div>
    
</template>

<script>
import { dynamicList,dtShow } from "@api/tasting";
import ToggleSwitch from '../../components/ToggleSwitch.vue';
import EditWindow from '../../components/EditWindow.vue';
import { Image as VanImage ,Loading} from 'vant';
const NAME = "list";

export default {
  name: NAME,
  components: {
    ToggleSwitch,Loading,EditWindow
  },
  props: {},
  data: function() {
    return {
      list: [],
      page:1,
      loading: false,
      finished: false,
      url:'/Tasting/user/dynamic/add'
    };
  },
  mounted: function() {
    this.getdynamic();
    this.beforeCreate();
    window.addEventListener("scroll", function(event) {
        var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
            that.Loadmore();
        }
    });
  },
  methods: {
    beforeCreate() {
         document.querySelector('body').setAttribute('style', 'background-color: #fff;')
    },
    Loadmore: function() {
            if(this.loading) return;
            if(this.finished) return;
            this.getdynamic();
    },
    getdynamic: function(){
        let that = this;
      dynamicList({
          page:this.page,
          limit:6
      }).then(res => {
            var listdata = res.data.data;
            listdata.forEach((item)=>{
                this.list.push(item);
            })
            this.loading = false;
            this.page++;
            if(listdata.length<5){
                this.finished = true;
            }
        })
        .catch(err => {
            this.loading = false;
            Toast.fail({
                message: err.msg,
                forbidClick: true,
            });
        });
    },
    dtShows(itemid){
        dtShow({
            id:itemid
        }).then(res => {
            console.log(res)
        });
    },
    goPages: function(url) {
      this.$router.push({ path: url });
    },
  }
};
</script>
<style scoped>
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.item{background: #fff;padding: 0.2rem;border-bottom: 1px solid #eee;position: relative;}
.item .pic{float: left;width: 2rem;height: 2rem;margin-right: 0.5rem;border-radius: 0.2rem;overflow: hidden;}
.item .pic img{width: 100%;height: 100%;}
.item .con{float: left;width: 3rem;}
.item .con .tit{height: 1.5rem;margin-top: 0.1rem;}
.item .con .time{color: #999;font-size: 0.2rem;}
.item .switch{position: absolute;bottom: 0.2rem;right: 0.1rem;width: 1rem;/*height: 0.4rem;*/}
.item .status{position: absolute;top: 0.3rem;right: 0.2rem;font-size: 0.2rem;color: #999;}

/* .goadd{position: fixed;bottom: 60rpx; right: 40rpx;color: #fff;width: 80rpx;height: 80rpx;line-height: 80rpx;border-radius: 100%;text-align: center;}
.goadd text{font-size: 36rpx;} */
</style>