<template>
    <a href="javascript:void(0);" class="release" @click.prevent="onSelect(url)">
      <div class="iconfont iconbianji border-color font-color"></div>
      <div></div>
    </a>
</template>

<script>
    export default {
        name: "EditWindow",
        data: function() {
            return {
            
            };
        },
        props: {
          url: {
            type: String,
            default: '/Tasting/user'
          },
	    },
        mounted: function() {

        },
        methods: {
           onSelect(item) {
                this.$router.push({ path: item});
            },
        }
    }
</script>

<style scoped lang="scss">
// .backtop{
//     position: fixed;
//     right: 0.3rem;
//     bottom: 2rem;
//     z-index: 100;
//     width: 0.8rem;
//     height: 0.8rem;
//     background: #ccc;
//     text-align: center;
//     line-height: 0.8rem;
//     text-decoration: none;
//     border-radius: 0.8rem;
//     display: block;
// }
.release{
    position: fixed;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 100;
    border-radius: 0.8rem;
    display: block;
    bottom: 60px;
    text-align: center;
    right: 0.3rem;
    .iconfont{
      width: 100%;height: 100%!important;
      line-height: 0.72rem!important;
      position: absolute;
      background: #e93323;
      border-width: 2px!important;
      border-radius: 0.8rem;
    }
    .font-color {
       color: #fff!important;
    }
  }
</style>